<template>
  <!-- Start About Us -->
  <section class="about-us" id="about-us">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 info">
          <div class="about-info">
            <h3>Welcome To Phoenix Heart Yoga</h3>
            <h4></h4>
            <p>
              Hello, everybody! 
              
            </p>
            <p>
              Welcome to my page! I am so happy that you have decided to take a step
              forward to a better and healthier life! Yoga is all about creating a balance in your life…. 
              It is about balancing your senses, balancing your body, soul and mind. I am excited to welcome you 
              into my classes and share this beautiful journey together!
            </p>
            <!--
            <div class="about-progress">
              <div class="progress-container">
                <span class="percent" style="left: calc(90% - 21px);">90%</span>
                <h4>Spirituality</h4>
                <div class="progress">
                  <div class="progress-bar"></div>
                </div>
              </div>
              <div class="progress-container">
                <span class="percent" style="left: calc(85% - 21px);">85%</span>
                <h4>Flexibility</h4>
                <div class="progress">
                  <div class="progress-bar"></div>
                </div>
              </div>
              <div class="progress-container">
                <span class="percent" style="left: calc(70% - 21px);">70%</span>
                <h4>Breathing</h4>
                <div class="progress">
                  <div class="progress-bar"></div>
                </div>
              </div>
            </div>-->
           <!-- <router-link to="/about-us" target="_blank" class="main-btn"><span>Read More</span></router-link>-->
          </div>
        </div>
        <div class="col-lg-6 image">
          <div class="about-image">
            <div class="about-bg"><i class="flaticon-lotus"></i></div>
            <img class="img-fluid" src="https://i.ibb.co/Qfg7FnN/lotus.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End About Us -->
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped></style>